<template>
  <div class="people">

    <div class="user-main">
      <PlayVideo v-if="showMask" ref="PlayVideo" :video-src="videoPath" />
      <NavImg class="NavImg" />
      <ul class="main-top">
        <li
          v-for="(item, index) in dataList"
          :key="item.id"
          style="margin-right: 22px"
        >
          <div
            class="first"
            :class="{ mask: type === 'video' && index === 0 }"
            @click="clickOpen(item)"
          >
            <div v-if="type === 'video' && index === 0" class="beforeMask">
              <img
                src="https://thcdn.gggamedownload.com/source/assets/bofang.png"
                alt=""
              >
            </div>
            <img class="photo" :src="item.img" @click="clickOpen(item)">
          </div>
          <div class="figure">
            <p class="name">{{ item.title }}</p>
            <p class="author">{{ item.author }}</p>
          </div>
        </li>
      </ul>
      <!-- <div class="pv">
        <ul class="top">
          <li
            v-for="item in titleList"
            :key="item.id"
            :class="{ active: item.id === activeId }"
            @click="activeId = item.id"
          >
            {{ item.title }}
          </li>
        </ul>
        <ul class="bottom">
          <li
            v-for="item in moreList"
            :key="item.id"
            style="margin-right: 10px"
            @click="bgClick(item.id)"
          >
            <img :src="item.img" alt="">
          </li>
        </ul>
        <div class="see-more">
          <span>查看更多</span>
        </div>
      </div> -->
    </div>
    <div
      v-if="imgShow"
      class="main-img"
      @click="closeClick"
    >
      <img :src="showImgPath" alt="">
    </div>
  </div>
</template>
<script>

import PlayVideo from '@/components/PlayVideo'
const bi1 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/girl-a.png'
const bi2 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/pass-a.png'
const bi3 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/speak-a.png'
const bi4 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/dog-a.png'
const bi5 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/ling-a.png'
const more1 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/girl-m.jpg'
const more2 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/pass-m.jpg'
const more3 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/speak-m.jpg'
const more4 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/dog-m.jpg'
const more5 = 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/ling-m.jpg'
// import { reqGetVideoList } from '@/api/video'
import NavImg from '@/components/Video_img'
export default {
  components: { PlayVideo, NavImg },
  data() {
    return {
      type: 'video',
      videoPath: '',
      queryForm: {
        channel: 'th.gg.com',
        pageSize: 5,
        currentPage: 1
        // tag: 'waller'
      },
      titleList: [
        { id: 1, title: 'PV' },
        { id: 2, title: '角色' },
        { id: 3, title: '剧情' },
        { id: 4, title: '其他' }
      ],
      activeId: 1,
      showMask: false,
      imgShow: false,
      wallerList: [
        {
          id: 1,
          title: '飞翔于空中的不可思议巫女',
          author: '作者：えれっと',
          img: bi1,
          path: more1
        },
        {
          id: 2,
          title: '通行禁止',
          author: '作者：萩原凛',
          img: bi2,
          path: more2
        },
        {
          id: 3,
          title: '无止境的说教',
          author: '作者：山鸟おふう',
          img: bi3,
          path: more3
        },
        {
          id: 4,
          title: '清廉正直的鸦天狗',
          author: '作者：ヒナユキウサ',
          img: bi4,
          path: more4
        },
        {
          id: 5,
          title: '半灵',
          author: '作者：东方归言录',
          img: bi5,
          path: more5
        }
      ],
      dataList: [],
      videoList: [
        {
          id: 1,
          title: 'MV-Project第1弹—《指尖的热度》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/1-MV-2.jpg',
          path: 'https://thcdn.gggamedownload.com/source/MV/1-MV-2.mp4'
        },
        {
          id: 2,
          title: 'MV-Project第2弹《斑驳的木春菊》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/2-MV-1.jpg',
          path: 'https://thcdn.gggamedownload.com/source/MV/2-MV-1.mp4'
        },
        {
          id: 3,
          title: 'MV-Project第3弹—《十六夜与月》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/3-MV-5.jpg',
          path: 'https://thcdn.gggamedownload.com/source/MV/3-MV-5.mp4'
        },
        {
          id: 4,
          title: 'MV-Project第4弹—《白银之风》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/4-MV-4.jpg',
          path: 'https://thcdn.gggamedownload.com/source/MV/4-MV-4.mp4'
        },
        {
          id: 5,
          title: 'MV-Project第5弹—《NAЯAKA》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/5-MV-3.jpg',
          path: 'https://thcdn.gggamedownload.com/source/MV/5-MV-3.mp4'
        }
      ],
      moreList: [
        { id: 1, img: bi1 },
        { id: 2, img: bi2 },
        { id: 3, img: bi5 }
      ],

      showImgPath: ''
    }
  },
  watch: {
    type() {
      this.getList()
    }
  },
  created() {
    this.type = this.$route.query.type
    this.getList()
  },
  methods: {
    async getList() {
      // const res = await reqGetVideoList(this.queryForm)
      // this.dataList = res.data
      // console.log(res)

      if (this.type === 'video') {
        this.dataList = this.videoList
      }
      if (this.type === 'img') {
        this.dataList = this.wallerList
      }
    },
    clickOpen({ path }) {
      if (this.type === 'video') {
        this.showMask = true
        this.videoPath = path
      }
      if (this.type === 'img') {
        this.showImgPath = path
        this.imgShow = true
      }
    },
    bgClick(id) {},
    closeClick() {
      this.imgShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
.people {
  width: 100%;
  height: 1200px;
  background: url('https://thcdn.gggamedownload.com/source/assets/neiye/sucai-6.jpg');
  background-size: 100% 100%;
  .user-main {
    width: 1000px;
    margin: 0 auto;

    .main-top {
      width: 100%;
      // padding-top: 62px;
      li {
        display: inline-block;
        cursor: pointer;
        .photo {
          width: 301.6px;
        }
        &:nth-child(1) {
          img {
            width: 453px;
          }
          .first {
            position: relative;
            .beforeMask {
              border: 1px solid #daba85;
              // background: url('https://thcdn.gggamedownload.com/source/assets/bofang.png') no-repeat center;
              // background-size: c;
              width: 97%;
              height: 92%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              z-index: 1;

              img {
                width: 106px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                z-index: 1;
              }
            }
          }
          .mask {
            &::after {
              content: '';
              // border: 1px solid #daba85;
              // background: url('https://thcdn.gggamedownload.com/source/assets/bofang.png') no-repeat center;
              background-color: rgba(0, 0, 0, 0.5);
              width: 100%;
              height: 100%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
            }
          }
        }
        &:nth-child(2) {
          position: relative;
          img {
            display: inline-block;
            width: 453px;
          }
          .figure {
           position: absolute ;
            top: 0;
            left: 0;
            transform: translateY(-145%);
          }
        }
        &:nth-child(3) {
          margin-top: 31px;
        }
      }
    }
    .pv {
      padding-top: 148px;
      .top {
        width: 556px;
        line-height: 34px;
        text-align: center;
        margin: 0 auto;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        li {
          display: flex;
          display: inline-block;
          margin: 0 20px;
          padding: 0 10px;
          cursor: pointer;
          &.active {
            border-bottom: 3px solid #5c6371;
          }
        }
      }
      .bottom {
        width: 960px;
        padding-top: 71px;
        li {
          display: inline-block;
          img {
            width: 301.6px;
            cursor: pointer;
          }
        }
      }
      .see-more {
        width: 311px;
        height: 44px;
        background: #f3f3f3;
        line-height: 44px;
        margin: 0 auto;
        margin-top: 56px;
        text-align: center;
        cursor: pointer;
        span {
          color: #5c6371;
        }
      }
    }
    .figure {
      margin: 5px 0;
      border-left: 3px solid #2f3c49;
      padding-left: 8px;
    }
    .name {
      color: #5c6371;
      font-size: 18px;
      line-height: 19px;
      border-left: 2px solod #000;
    }
    .author {
      color: #a7a7a7;
      font-size: 13px;
      line-height: 19px;
    }
  }
  .main-img {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    img {
     width: 100%;
    }
  }

  .NavImg {
    // display: flex;
    // justify-content: center;
    // padding-top: 20px;
    padding: 32px 0;
  }
}
</style>
