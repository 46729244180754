<template>
  <div class="soundtrack">
    <div class="video">
      <Head />

    </div>
    <!-- <div class="navigation">
      <span :class="{active: activeType ==='video'}" @click="changeType('video')">视频中心</span>
      <span :class="{active: activeType ==='img'}" @click="changeType('img')">高清壁纸</span>
    </div> -->
    <People ref="People" />
    <!-- <Reserve :open="open" />
    <ReserveModels ref="ReserveModels" /> -->

    <Floor />
  </div>
</template>
<script>
import Head from "@/components/Head";
import Floor from "@/components/Floor";
import People from "./models/people";
import Reserve from "@/components/Reserve";
import ReserveModels from "@/components/ReserveModels";

export default {
  name: "Soundtrack",
  components: { Head, Floor, People, Reserve, ReserveModels },
  data() {
    return {
      activeType: null
    };
  },
  created() {
    this.activeType = this.$route.query.type
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  },
  methods: {
    changeType(type) {
      // this.$router.push({ path: "/soundtrack" })
      this.$refs['People'].type = type
      this.activeType = type
    },
    open() {
      this.$refs['ReserveModels'].showGame = true
    }
  }
};
</script>
<style lang="scss" scoped>
.Soundtrack {
  position: relative;
}
.video {
  width: 100%;
  height: 375px;
  background: url("https://thcdn.gggamedownload.com/source/assets/neiye/sucai-14.jpg");
  .navigation {
    padding-top: 252px;
    margin-left: 1182px;
    span {
      width: 110px;
      height: 29px;
      font-size: 12px;
      color: #ffffff;
      border-radius: 15px;
      padding: 3px 8px;
      margin-left: 12px;
      cursor: pointer;
      &.active {
      border: 1px solid #ffffff;

      }
    }
  }
}
</style>
