<template>
  <div class="navChange">
    <div class="navigation">
      <!-- <span>视听盛宴</span> -->
      <span :class="{active: activeType ==='video'}" @click="changeType('video')">视频中心</span>
      <span :class="{active: activeType ==='img'}" @click="changeType('img')">高清壁纸</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeType: ''
    }
  },
  created() {
    this.activeType = this.$route.query.type
  },
  methods: {
    changeType(type) {
      this.activeType = type
      this.$parent.type = type
      this.$router.replace({
        path: this.$route.path,
        query: {
          type
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation {
    span {
      width: 110px;
      height: 29px;
      font-size: 12px;
      border-radius: 15px;
      padding: 3px 8px;
      margin-left: 12px;
      cursor: pointer;
      &.active {
      border: 1px solid #409eff;
      background-color: #409eff;
      color: #fff;
      }
    }
  }
</style>
